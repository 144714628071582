<template>
    <v-card :height="kioskAnalyticsDimension.userSessionheight" flat>
        <v-row class="fill-height">
            <v-col cols="12" align="center" class="my-4">
                <v-btn width="150" class="mx-3" @click="selectedDuration = 'Today'"
                    v-bind:color="selectedDuration === 'Today' ? 'primary' : 'secondary'">Today</v-btn>
                <v-btn width="150" class="mx-3" @click="selectedDuration = 'Yesterday'"
                    v-bind:color="selectedDuration === 'Yesterday' ? 'primary' : 'secondary'">Yesterday</v-btn>
                <v-btn width="150" class="mx-3" @click="selectedDuration = 'This Week'"
                    v-bind:color="selectedDuration === 'This Week' ? 'primary' : 'secondary'">This Week</v-btn>
                <v-btn width="150" class="mx-3" @click="selectedDuration = '2024'"
                    v-bind:color="selectedDuration === '2024' ? 'primary' : 'secondary'">2024</v-btn>
                <v-btn width="150" class="mx-3"
                    @click="selectedDuration = 'Select Date'; $store.commit('setSingleDatePicker', true)"
                    v-bind:color="selectedDuration === 'Select Date' ? 'primary' : 'secondary'">Select Date
                </v-btn>
                <v-btn width="150" class="mx-3"
                    @click="selectedDuration = 'Select Week'; $store.commit('setWeekDatePicker', true)"
                    v-bind:color="selectedDuration === 'Select Week' ? 'primary' : 'secondary'">Select Week</v-btn>
                <v-btn width="150" class="mx-3"
                    @click="selectedDuration = 'Fiscal Year'; $store.commit('setFiscalYearPicker', true)"
                    v-bind:color="selectedDuration === 'Fiscal Year' ? 'primary' : 'secondary'">Fiscal Year</v-btn>
            </v-col>
            <v-col cols="12" align="center" class="font-weight-bold">Date: {{ displayDate }}</v-col>
            <v-col cols="5">
                <annual-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === '2024'"></annual-bar-chart>
                <this-week-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'This Week'"></this-week-bar-chart>
                <today-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Today'"></today-bar-chart>
                <today-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Yesterday'"></today-bar-chart>
                <today-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Select Date'"></today-bar-chart>
                <this-week-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Select Week'"></this-week-bar-chart>
                <FiscalYearBarChart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Fiscal Year'"></FiscalYearBarChart>
            </v-col>
            <v-col cols="4" align="center">
                <pie-chart></pie-chart>
            </v-col>
            <v-col cols="3" align="center">
                <language-bar-chart></language-bar-chart>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import AnnualBarChart from '../../../graphs/annualBarChart.vue';
import ThisWeekBarChart from '../../../graphs/thisWeekBarChart.vue';
import TodayBarChart from '../../../graphs/todayBarChart.vue';
import FiscalYearBarChart from '../../../graphs/fiscalYearBarChart.vue';
import PieChart from '../../../graphs/pieChart.vue';
import LanguageBarChart from '../../../graphs/languageBarChart.vue';
export default {
    name: 'reports',
    props: {
        userSessionList: Array
    },
    computed: {
        ...mapGetters(['kioskAnalyticsDimension', 'selectedSingleDate', 'weekDatePicker', 'selectedWeekDate', 'singleDatePicker', 'displayDate', 'closeWithoutSelection'])
    },
    components: {
        AnnualBarChart,
        ThisWeekBarChart,
        TodayBarChart,
        PieChart,
        LanguageBarChart,
        FiscalYearBarChart
    },
    data() {
        return {
            selectedDuration: 'Today'
        }
    },
    watch: {
        closeWithoutSelection(newVal) {
            if (newVal === 1) {
                //
                this.selectedDuration = 'Today'

            }
        },
        selectedDuration(newVal) {
            console.log('Coming in here: ', newVal)
            if (newVal === 'Today') {
                this.$store.commit('setSelectedSingleDate', null)
                this.$store.commit('setSelectedWeekDate', null)
                this.$store.dispatch('getKioskAnalytics', {
                    type: 'Today',
                    start: null,
                    end: null
                })
                this.$store.commit('setcloseWithoutSelection', 0)
            } else if (newVal === 'This Week') {
                this.$store.commit('setSelectedSingleDate', null)
                this.$store.commit('setSelectedWeekDate', null)
                this.$store.dispatch('getKioskAnalytics', {
                    type: 'This Week',
                    start: null,
                    end: null
                })
            } else if (newVal === '2024') {
                this.$store.commit('setSelectedSingleDate', null)
                this.$store.commit('setSelectedWeekDate', null)
                this.$store.dispatch('getKioskAnalytics', {
                    type: '2024',
                    start: null,
                    end: null
                })
            } else if (newVal === 'Yesterday') {
                this.$store.commit('setSelectedSingleDate', null)
                this.$store.commit('setSelectedWeekDate', null)
                this.$store.dispatch('getKioskAnalytics', {
                    type: 'Yesterday',
                    start: null,
                    end: null
                })
            } else if (newVal === 'Select Date') {
                this.$store.commit('setSelectedWeekDate', null)
            }
            else {
                //this.$store.commit('setSelectedSingleDate', null)
            }
        }
    }

}
</script>
<style>
.cardBorderStyle {
    border-right: solid 1px #111111 !important;
    border-radius: 0px !important;
}
</style>
